import React, { useEffect, useState } from 'react'
import { TopBanner } from '../components/TopBanner'
import styled from '@emotion/styled'
import { Button, CircularProgress } from '@mui/material'
import Alert from '@mui/material/Alert'
import { navigate, Link } from 'gatsby'

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import CardHeader from '@mui/material/CardHeader'
import DangerousIcon from '@mui/icons-material/Dangerous'

//import Grid from '@mui/system/Unstable_Grid'
import Grid from '@mui/material/Grid'
import { CardActionArea } from '@mui/material'

import Typography from '@mui/material/Typography'

import LoginIcon from '@mui/icons-material/Login'
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard'
import ManageSearch from '@mui/icons-material/ManageSearch'
import Search from '@mui/icons-material/Search'
import EuroIcon from '@mui/icons-material/Euro'

interface Props {
  Comp: React.ComponentType<{ value: string }>
}

const isBrowser = typeof window !== 'undefined'

function use() {
  // add error handling
  if (isBrowser) {
    navigate('/search')
  }
}

function create() {
  // add error handling
  if (isBrowser) {
    navigate('/create')
  }
}
function view() {
  // add error handling
  if (isBrowser) {
    navigate('/search?type=view')
  }
}
function edit() {
  // add error handling
  if (isBrowser) {
    navigate('/search?type=edit')
  }
}
function createDocrates() {
  // add error handling
  if (isBrowser) {
    navigate('/docrates')
  }
}

interface AuthState {
  isAuthenticated: boolean
  isAdmin: boolean
  isDocrates: boolean
  isLoyalty: boolean
  username: string
  loading: boolean
  error: string | null
}

const Page = () => {
  const [auth, setAuth] = useState<AuthState>({
    isAuthenticated: false,
    isAdmin: false,
    isDocrates: false,
    isLoyalty: false,
    username: '',
    loading: true,
    error: null,
  })

  useEffect(() => {
    const verifyAuth = async () => {
      const authToken = isBrowser ? localStorage.getItem('myauth') : ''
      if (!authToken) {
        setAuth(prev => ({
          ...prev,
          loading: false,
          error: 'No auth token found',
        }))
        return
      }
      try {
        const response = await fetch(`/getAuth`, {
          method: 'GET',
          headers: {
            authorization: authToken,
          },
        })

        if (!response.ok) {
          throw new Error('Authentication failed')
        }

        const data = await response.json()
        setAuth({
          isAuthenticated: true,
          isAdmin: data.isadmin,
          isDocrates: data.isdocrates,
          isLoyalty: data.isloyalty,
          username: data.username,
          loading: false,
          error: null,
        })
      } catch (error) {
        setAuth(prev => ({
          ...prev,
          loading: false,
          error: 'Authentication failed',
        }))
      }
    }

    verifyAuth()
  }, [])

  if (auth.loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    )
  }

  if (auth.error || !auth.isAuthenticated) {
    return (
      <Container>
        <Alert variant="filled" severity="error" sx={{ width: '100%' }}>
          Kirjaudu sisään käyttääksesi palvelua
        </Alert>
        <LinkContainer>
          <Link to="/">Palaa sisäänkirjautumissivulle</Link>
          <LoginIcon sx={{ ml: 1, color: 'black' }} />
        </LinkContainer>
      </Container>
    )
  }

  return (
    <>
      <div>
        <TopBanner user={auth.username} />
      </div>
      <Container>
        <PageTitle>Valitse toiminto</PageTitle>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={5}
          columns={18}
        >
          {/* Fysios card - show for admin and loyalty roles */}
          {(auth.isAdmin || auth.isLoyalty) && (
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardActionArea onClick={create}>
                  <CardMedia
                    image="/Physiotherapy.svg"
                    title="Fysios"
                    component="img"
                    sx={{
                      padding: 1,
                      width: 60,
                      margin: '0 auto',
                      display: 'block',
                    }}
                  />
                  <CardContent>
                    <Typography variant="body1" color="text.primary">
                      Luo uusi ennakkomaksu tai lahjakorttikoodi asiakkaalle
                      Fysioksen toimipisteessä
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="body2" color="#007864">
                      Siirry luomaan lahjakortti/ennakkomaksu
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}

          {/* Hammas card - show for admin and loyalty roles */}
          {(auth.isAdmin || auth.isLoyalty) && (
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardActionArea onClick={use}>
                  <CardMedia
                    image="/Tooth.svg"
                    title="Hammas"
                    component="img"
                    sx={{
                      padding: 1,
                      width: 60,
                      margin: '0 auto',
                      display: 'block',
                    }}
                  />
                  <CardContent>
                    <Typography variant="body1" color="text.primary">
                      Käytä lahjakorttikoodi Hampaan toimipisteessä
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="body2" color="#007864">
                      Siirry käyttämään lahjakorttia
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}

          {/* View card - show for admin and loyalty roles */}
          {(auth.isAdmin || auth.isLoyalty) && (
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardActionArea onClick={view}>
                  <CardContent>
                    <CardMedia
                      image="/Search.svg"
                      title="Hammas"
                      component="img"
                      sx={{
                        padding: 1,
                        width: 60,
                        margin: '0 auto',
                        display: 'block',
                      }}
                    />
                    <Typography variant="body1" color="text.primary">
                      Tarkista ennakkomaksun/lahjakortin tiedot tai tulosta
                      kortti
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="body2" color="#007864">
                      Tarkista tiedot
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}

          {/* Docrates create card - show for admin and docrates roles */}
          {(auth.isAdmin || auth.isDocrates) && (
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardActionArea onClick={createDocrates}>
                  <CardMedia
                    image="/Ribbon.svg"
                    title="Docrates"
                    component="img"
                    sx={{
                      padding: 1,
                      width: 60,
                      margin: '0 auto',
                      display: 'block',
                    }}
                  />
                  <CardContent>
                    <Typography variant="body1" color="text.primary">
                      Luo uusi Docrates -ennakkomaksu asiakkaalle
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="body2" color="#007864">
                      Siirry luomaan ennakkomaksu
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}

          {/* Docrates edit card - show for admin and docrates roles */}
          {(auth.isAdmin || auth.isDocrates) && (
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardActionArea onClick={edit}>
                  <CardMedia
                    image="/MoneyCheck.svg"
                    title="Docrates"
                    component="img"
                    sx={{
                      padding: 1,
                      width: 60,
                      margin: '0 auto',
                      display: 'block',
                    }}
                  />
                  <CardContent>
                    <Typography variant="body1" color="text.primary">
                      Tarkista Docrates ennakkomaksun tiedot tai korjaa
                      ennakkomaksun summaa
                    </Typography>
                    <br />
                    <Typography variant="body2" color="#007864">
                      Hae ennakkomaksu
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  )
}

export default Page

const PageTitle = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 0 15px 15px;
  text-align: center;
  font-size: 1.5rem;
  font weight: 700;
  padding: 40px;
`

const Container = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 0 15px 15px;
  text-align: center;
`

const ButtonContainer = styled.div`
  max-width: 1400px;
  margin: auto;
  padding: 0 15px 15px;
  text-align: center;
`

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`
